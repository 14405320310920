import { createContext, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { backendRequest } from '../utils/axiosInstance'
import createApp from '@shopify/app-bridge'
// import { getSessionToken } from '@shopify/app-bridge/utilities'
import { AppLink, NavigationMenu } from '@shopify/app-bridge/actions'

export const DataUpdateContext = createContext()

export const DataUpdateProvider = ({ children }) => {
  const [lastUpdate, setLastUpdate] = useState(null)
  const [steps, setSteps] = useState(null)
  const [jobStatus, setJobStatus] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  // const [sessionToken, setSessionToken] = useState(null)
  const [appBridge, setAppBridge] = useState(null)
  const [isLogin, setIsLogin] = useState(null)
  const [landingUrl, setLandingUrl] = useState(null)
  const [companyStatus, setCompanyStatus] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState('free')
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])
  const embedded = queryParams.get('embedded')
  const host = queryParams.get('host')
  const { t, i18n } = useTranslation()

  const getLandingUrl = ({ companyStatus, subscriptionStatus }) => {
    if (subscriptionStatus !== 'active') return '/replenishment'
    if (companyStatus === 'onboarding' || companyStatus === 'new') return '/replenishment'
    if (companyStatus === 'active') return '/replenishment'
    return '/replenishment'
  }

  useEffect(() => {
    if (embedded === '1') {
      const fetchLocale = async ({ appBridge }) => {
        const appState = await appBridge.getState()
        const userLanguage = appState?.staffMember?.locale || 'en-US'
        if (i18n.language !== userLanguage) i18n.changeLanguage(userLanguage)
      }
      if (!host) {
        console.error('Host must be provided for embedded mode.')
        return
      }
      const appBridgeInitial = createApp({
        apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
        host,
        embedded
      })

      setAppBridge(appBridgeInitial)

      fetchLocale({ appBridge: appBridgeInitial })

      const replenishmentViewLink = AppLink.create(appBridgeInitial, {
        label: t('sidebar.replenishment'),
        destination: '/replenishment'
      })
      // const welcomeViewLink = AppLink.create(appBridgeInitial, {
      //   label: 'Welcome',
      //   destination: '/welcome'
      // })
      const settingViewLink = AppLink.create(appBridgeInitial, {
        label: t('sidebar.setting'),
        destination: '/setting'
      })
      const planViewLink = AppLink.create(appBridgeInitial, {
        label: t('sidebar.plan'),
        destination: '/plan-selection'
      })

      NavigationMenu.create(appBridgeInitial, {
        items: [
          replenishmentViewLink,
          planViewLink,
          settingViewLink
        ]
      })
    }
  }, [embedded, host, t, i18n])

  useEffect(() => {
    const login = async () => {
      if (!embedded || !appBridge || !queryParams) return
      try {
        await backendRequest({ method: 'POST', url: '/auth/shopify-login', appBridge, data: { queryString: queryParams.toString() } })
        setIsLogin(true)
      } catch (error) {
        console.error('Error loging in', error)
        setIsLogin(false)
      }
      setIsLogin(true)
    }
    login()
  }, [embedded, appBridge, queryParams])

  useEffect(() => {
    const fetchMyCompany = async () => {
      if (embedded && !isLogin) return
      try {
        const { data: myCompany } = await backendRequest({ method: 'GET', url: '/obius/companies/my-company', appBridge })
        const companyStatus = myCompany.company_status
        setCompanyStatus(companyStatus)
        const companyId = myCompany.company_id
        setCompanyId(companyId)
        const subscriptionStatus = myCompany.subscription_status
        const landingUrl = getLandingUrl({ companyStatus, subscriptionStatus })
        setLandingUrl(landingUrl)
        setSubscriptionPlan(myCompany.subscription_plan)
        const lastUpdate = myCompany.last_update
        if (lastUpdate) {
          setLastUpdate(lastUpdate)
        } else {
          const interval = setInterval(async () => {
            const { data: { jobs: [job] } } = await backendRequest({
              method: 'get',
              url: `/job/jobs?company_id=${companyId}&job_type=initial_end_to_end,end_to_end&limit=1`,
              appBridge
            })
            if (!job) return
            setSteps(job.steps)
            if (job.status === 'succeeded') {
              setLastUpdate(job.start_date)
              clearInterval(interval)
            }
            if (job.status === 'failed' && job.job_type === 'initial_end_to_end') {
              setJobStatus(job.status)
              clearInterval(interval)
            }
          }, 5000)
        }
      } catch (error) {
        console.error('Error fetching last succeeded job', error)
        setIsLogin(false)
      }
    }
    fetchMyCompany()
  }, [appBridge, isLogin, embedded])

  return (
    <DataUpdateContext.Provider value={{ lastUpdate, companyId, isLogin, companyStatus, appBridge, landingUrl, subscriptionPlan, steps, jobStatus }}>
      {children}
    </DataUpdateContext.Provider>
  )
}
