import { useContext, useState } from 'react'
import { Page, Grid, Card, List, Text, Button, Box, SkeletonPage, Bleed, Divider, BlockStack, InlineStack } from '@shopify/polaris'
import '@shopify/polaris/build/esm/styles.css'
import { useNavigate } from 'react-router-dom'
import { backendRequest } from '../../utils/axiosInstance'
import { DataUpdateContext } from '../../context/context'
import { useTranslation } from 'react-i18next'

export const PlanSelectionView = () => {
  const navigate = useNavigate()
  const { companyId, appBridge, subscriptionPlan } = useContext(DataUpdateContext)
  const [loadingFreePlan, setLoadingFreePlan] = useState(false)
  const [loadingPremiumPlan, setLoadingPremiumPlan] = useState(false)
  const { t } = useTranslation()

  const handleFreePlanSelection = async () => {
    setLoadingFreePlan(true)
    try {
      const { data: { return_url: returnUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'free'
        }
      })
      navigate(`/shopify/subscription/return?${returnUrl.split('?')[1]}`)
    } catch (error) {
      console.error('Failed to create free subscription.', error)
    } finally {
      setLoadingFreePlan(false)
    }
  }

  const handlePremiumPlanSelection = async () => {
    setLoadingPremiumPlan(true)
    try {
      const { data: { confirmation_url: confirmationUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'premium'
        }
      })
      if (confirmationUrl) {
        window.open(confirmationUrl, '_blank')
      }
    } catch (error) {
      console.error('Failed to create premium subscription.', error)
    } finally {
      setLoadingPremiumPlan(false)
    }
  }

  const freePlanButtonLabel = subscriptionPlan === 'free' ? t('button.current_plan') : t('button.downgrade')

  const premiumPlanButtonLabel = subscriptionPlan === 'premium' ? t('button.current_plan') : t('button.upgrade')
  const isFreePlanDisabled = subscriptionPlan === 'free' || !subscriptionPlan
  const isPremiumPlanDisabled = subscriptionPlan === 'premium'

  return (
    <Page>
      { companyId
        ? (
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
          <Card>
            <Box padding='300'>
              <BlockStack gap="100">
                <Text as='h2'>
                {t('plan.free.title')}
                </Text>
                <InlineStack wrap={false} blockAlign='baseline' gap='100'>
                  <Text variant='heading2xl'>{t('plan.free.price')}</Text>
                </InlineStack>
              </BlockStack>
            </Box>
            <Divider />
            <Box minHeight='100px' padding='300'>
              <List type="bullet">
                <List.Item>
                {t('plan.free.items.variants')}
                </List.Item>
                <List.Item>
                {t('plan.free.items.alerts')}
                </List.Item>
                {/* <List.Item>
                {t('plan.free.items.email')}
                </List.Item> */}
                <List.Item>
                {t('plan.free.items.data_refresh')}
                </List.Item>
              </List>
            </Box>
            <Box padding='300'>
              <Button
                variant="primary"
                fullWidth
                loading={loadingFreePlan}
                onClick={handleFreePlanSelection}
                disabled={isFreePlanDisabled}
              >
                {freePlanButtonLabel}
              </Button>
            </Box>
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }} className='grid-cell'>
          <Card>
            <Box padding='300'>
              <BlockStack gap="100">
                <Text as='h2'>
                {t('plan.premium.title')}
                </Text>
                <InlineStack wrap={false} blockAlign='baseline' gap='100'>
                  <Text variant='heading2xl'>{t('plan.premium.price')}</Text>
                  <Text>/ {t('plan.premium.month')}</Text>
                </InlineStack>
              </BlockStack>
            </Box>
            <Divider borderColor="border"/>
            <Box minHeight='100px' padding='300'>
              <List type="bullet">
                <List.Item>
                {t('plan.premium.items.variants')}
                </List.Item>
                <List.Item>
                {t('plan.premium.items.alerts')}
                </List.Item>
                {/* <List.Item>
                {t('plan.premium.items.email')}
                </List.Item> */}
                <List.Item>
                {t('plan.premium.items.export')}
                </List.Item>
                <List.Item>
                {t('plan.premium.items.data_refresh')}
                </List.Item>
              </List>
              <Bleed>
                <Box
                  background="bg-surface-secondary"
                  paddingBlock="300"
                  paddingInline="400"
                >
                  <Text as="h3" variant="headingSm" fontWeight="medium">
                  {t('plan.premium.trial')}
                  </Text>
                </Box>
              </Bleed>
            </Box>
            <Box padding='300'>
              <Button
                variant="primary"
                fullWidth
                loading={loadingPremiumPlan}
                onClick={handlePremiumPlanSelection}
                disabled={isPremiumPlanDisabled}
              >
                {premiumPlanButtonLabel}
              </Button>
            </Box>
          </Card>
        </Grid.Cell>
      </Grid>)
        : (
        <SkeletonPage>
          <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
          <Card>
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }} className='grid-cell'>
          <Card>
          </Card>
        </Grid.Cell>
      </Grid>
        </SkeletonPage>
          )
      }
    </Page>
  )
}
